@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #fff;
}

#fullpage {
    overflow: hidden;
}

.main-image {
    width: calc(100vw - 48px);
    height: calc(100vh - 90px);
    object-fit: contain;
    margin: 0 auto;
}

.fp-watermark {
    display: none;
}

.fp-slidesNav.fp-top {
    top: 8px;
    left: 0;
    right: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.fp-arrow {
    opacity: 0.35;
    transition: opacity 0.3s;
}

.fp-arrow:hover {
    opacity: 1;
}

.fp-arrow.fp-next {
    border-width: 38.5px 0 38.5px 34px;
    border-color: #0000 #0000 #0000 #FEC001;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    background: #fec001;
}

.fp-arrow.fp-prev {
    border-width: 38.5px 34px 38.5px 0;
    border-color: #0000 #FEC001 #0000 #0000;
}
